import _ from 'lodash';
import moment from 'moment';
import ChoixPermis from './ChoixPermis/ChoixPermis';
import CrmAuto50Plus3Ans from './CrmAuto50Plus3Ans/CrmAuto50Plus3Ans';
import CrmMoto50Plus3Ans from './CrmMoto50Plus3Ans/CrmMoto50Plus3Ans';
import GoToAntecedents from './GoToAntecedents/GoToAntecedents';
import TitulaireCarteGrise from './TitulaireCarteGrise/TitulaireCarteGrise';
import {ValeurCrmAuto, ValeurCrmMoto, DateNaissance, DateNaissanceRepresentantLegal, DeclarationFormation, DeclarationAssurance} from '@amo/core/components/questions';

const Profil = (props) => {
    const { data, id, vehicule } = props;
    const datePermisB = moment(_.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), ['TypePermis', 'B'])?.DatePermis, 'DD/MM/YYYY')

    const souscripteurIsMineur = (data) => {
        return (
            moment(_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'), 'DD/MM/YYYY').add(18, 'years') >=
            moment()
        );
    };

    const hasOnlyPermisB = (permis) => {
        return !(_.find(permis, ['TypePermis', 'A1']) || _.find(permis, ['TypePermis', 'A']) || _.find(permis, ['TypePermis', 'A2'])) && _.find(permis, ['TypePermis', 'B']);
    };

    return (
        <div id={id}>
            <DateNaissance {...props} />

            {souscripteurIsMineur(data) && <DateNaissanceRepresentantLegal {...props} />}

            <TitulaireCarteGrise {...props} />

            {moment(_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'), 'DD/MM/YYYY').add(14, 'years') <
                moment() &&
                <>
                    <ChoixPermis {...props} />

                    {((Number(data?.cylindreeVehicule) <= 125 && hasOnlyPermisB(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'))) ||
                        (vehicule?.genre === 'TRI' && !_.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis) => permis?.TypePermis === 'A'))) && (
                        <>
                            {(datePermisB >= moment('2011-01-01') || (datePermisB >= moment('1980-03-01') && datePermisB < moment('2011-01-01'))) &&
                                <DeclarationFormation {...props} datePermisB={datePermisB} />
                            }
                            {(datePermisB >= moment('1980-03-01') && datePermisB < moment('2011-01-01') && _.get(data, 'DemandeTarif[ListePersonnes][0][Formation]') === '0') &&
                                <DeclarationAssurance {...props} datePermisB={datePermisB} />
                            }
                        </>
                    )}
                </>
            }

            {!_.includes(['quad', 'verte', '50'], data?.typeVehicule) &&
                _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis) => {
                    return _.includes(['AM', 'A1', 'A2', 'A', 'B'], permis?.TypePermis);
                }) && <ValeurCrmMoto {...props} />}

            {Number(_.get(data, 'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]')) === 0.5 && (
                <CrmMoto50Plus3Ans {...props} />
            )}

            {!_.includes(['quad', 'verte', '50'], data?.typeVehicule) &&
                _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (permis) => {
                    return _.includes(['B'], permis?.TypePermis);
                }) && <ValeurCrmAuto {...props} />}

            {Number(_.get(data, 'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]')) === 0.5 && (
                <CrmAuto50Plus3Ans {...props} />
            )}

            <GoToAntecedents {...props} />
        </div>
    );
};

export default Profil;
