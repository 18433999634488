import call from '@amo/core/assets/images/icons/call.png';
import zarco from '@amo/core/assets/images/icons/zarco.png';
import { BulleAide } from '@amo/core/components/containers';
import { normalizeTel } from '@amo/core/utils/normalize.js';
import _ from 'lodash';
import Nom from './Nom/Nom';
import NumeroTel from './NumeroTel/NumeroTel';
import Prenom from './Prenom/Prenom';

const Coordonnees = (props) => {
    const { setStepForm, data, apporteur1, apporteur2, utm_source } = props;
    const telApporteur2 = {
        67256: '0279834142',
        100122: '0219011492',
        101853: '0219011491',
        393798: '0279834145',
    };
    let tel;

    if (apporteur1.codeBelair === 809466) {
        tel = '0487937213';
    } else if (apporteur2) {
        tel = telApporteur2[apporteur2.codeBelair] || (utm_source === 'lrdm' ? '0219011493' : '0219011494');
    } else if (utm_source === 'april') {
        tel = '0255453459';
    } else {
        tel = utm_source === 'lrdm' ? '0219011493' : '0219011494';
    }

    return (
        <>
            <div className="row justify-content-center">
                <div className={'col-12 mb-4'}>
                    <div className={'row align-items-center justify-content-center'}>
                        <div className={'col-4 col-lg-2 text-center'}>
                            <img src={zarco} alt={'être rappelé'} />
                        </div>
                        <div className={'col-8 col-lg-5 text-start'}>
                            <h2 className={'text-dark f-24 fw-bold mt-4 mb-2'}>Vous souhaitez être rappelé ?</h2>
                            <h3 className={'text-dark f-18 fw-bold mb-4'}>Saisissez vos coordonnées</h3>
                        </div>
                    </div>
                </div>
            </div>

            <Nom {...props} />

            <Prenom {...props} />

            <NumeroTel {...props} />

            <div className={'row justify-content-center'}>
                <div className={'col-lg-8 col-md-10 col-sm-10 col-12 my-2'}>
                    <div className={'row align-items-center'}>
                        <div className={'col-12 col-lg-7 text-start'}>
                            <p className={'f-14'}>
                                Notre service commercial est à votre écoute pour toute <strong>demande de devis</strong>{' '}
                                du lundi au vendredi de&nbsp;9h&nbsp;à&nbsp;19h et du samedi
                                de&nbsp;10h&nbsp;à&nbsp;14h.
                            </p>
                        </div>
                        <div className={'col-12 col-lg-5 text-lg-end text-center pt-2 pt-lg-0'}>
                            <a href={`tel:${tel}`} className={'f-20 fw-bold text-secondary text-decoration-none'}>
                                <img src={call} alt={'phone'} /> {normalizeTel(tel)}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            
            {apporteur1.codeBelair === 43397 && (
                <BulleAide
                    title={
                        "Attention, si vous êtes déjà client, merci d'appeler au <b>02&nbsp;47&nbsp;51&nbsp;07&nbsp;07</b>."
                    }
                    className={'my-4 col-lg-8 offset-lg-2 col-md-12'}
                />
            )}

            <span
                className={`cursor-pointer btn btn-primary btn-arrow btn-smaller mb-2 ${
                    (!_.get(data, 'booking_nom') ||
                        !_.get(data, 'booking_prenom') ||
                        !_.get(data, 'booking_tel') ||
                        !_.get(data, 'accept-recontact')) &&
                    'disabled'
                }`}
                onClick={() => {
                    setStepForm(2);
                }}
            >
                Suivant
            </span>
        </>
    );
};

export default Coordonnees;
