import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { getGestionnaireInit } from '../../../../redux/selectors/init/init.selectors';
import Footer from './Footer';

const mapStateToProps = (state) => {
    return {
        gestionnaireIsAMO: getGestionnaireInit(state).codeBelair === 43397,
        data: getFormValues('devis-direct')(state),
    };
};

const FooterContainer = connect(mapStateToProps)(Footer);

export default FooterContainer;
