import { Assure3DerniersMois, CarteGriseFrancaise, UsageNonLivraison, UsageVehicule, VehiculeImmatricule } from '@amo/core/components/questions';
import _ from 'lodash';
import ConnexionClientContainer from './ConnexionClient/ConnexionClientContainer';
import CylindreeVehiculeContainer from './CylindreeVehicule/CylindreeVehiculeContainer';
import DateAchat from './DateAchat/DateAchat';
import DateMec from './DateMEC/DateMEC';
import GoToProfil from './GoToProfil/GoToProfil';
import GoToVehicule from './GoToVehicule/GoToVehicule';
import LieuStationnementContainer from './LieuStationnement/LieuStationnementContainer';
import MarqueVehiculeContainer from './MarqueVehicule/MarqueVehiculeContainer';
import ModeleVehiculeContainer from './ModeleVehicule/ModeleVehiculeContainer';
import TypeVehicule from './TypeVehicule/TypeVehicule';
import SearchTypeVehicule from './SearchTypeVehicule/SearchTypeVehicule.jsx';
import SearchByImmat from './SearchByImmat/SearchByImmat.jsx';

const Vehicule = (props) => {
    const { data, id, changeValue, previousQuestion } = props;

    return (
        <div id={id}>
            <GoToVehicule {...props} />

            {/*en attente api immat*/}
            {/*<SearchVehiculeByImmatOrModele {...props} />
            { data?.searchBy === "immatriculation" && <SearchByImmat {...props} /> }*/}

            <TypeVehicule {...props} />

            {data.searchBy === 'modele' ? (
                <>
                    {data?.typeVehicule && <MarqueVehiculeContainer {...props} />}
                    {data?.marqueVehicule && <CylindreeVehiculeContainer {...props} />}
                    {data?.cylindreeVehicule && <ModeleVehiculeContainer {...props} />}
                </>
            ) : (
                <SearchByImmat {...props} />
            )}

            <DateMec {...props} />

            <DateAchat {...props} />

            {_.get(data, 'DemandeTarif[ListePersonnes][0][DejaClient]') === '1' &&
                <ConnexionClientContainer {...props} />}

            {_.get(data, 'DemandeTarif[Vehicule][PossessionPlusDe3Mois]') === '1' && <Assure3DerniersMois {...props} />}

            {_.includes(['quad', 'verte'], data?.typeVehicule) && (
                <>
                <VehiculeImmatricule title={'Votre véhicule est-il <b>immatriculé</b>&nbsp;?'} {...props} />
                </>
            )}

            {_.get(data, 'DemandeTarif[Vehicule][VehiculeImmatricule]') === '1' && <CarteGriseFrancaise {...props} />}

            {!_.includes(['quad', 'verte'], data?.typeVehicule) && <UsageVehicule {...props} title={'A quelle(s) <b>occasion(s)</b> <br/>utilisez-vous <b>votre véhicule</b>&nbsp;?'} />}

            {data?.cylindreeVehicule === '50' && <UsageNonLivraison {...props} redirectToLivraison={true} />}

            <LieuStationnementContainer {...props} />

            <GoToProfil {...props} />
        </div>
    );
};

export default Vehicule;
