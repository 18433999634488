import { SET_VEHICULE } from '../../actions/app/vehicule/vehicule.actions';

const initialState = null;

export const vehiculeReducer = (state = initialState, action) => {
    const { payload } = action;

    switch (action.type) {
        case SET_VEHICULE:
            if (payload && payload.data && payload.data.data) {
                return payload.data.data;
            } else {
                return state;
            }

        default:
            return state;
    }
};
