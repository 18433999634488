import main from '@amo/core/assets/images/AMO_Icons/Pas-avance-argent.svg';
import locker from '@amo/core/assets/images/icons/locker.png';
import notes from '@amo/core/assets/images/icons/notes.svg';
import paiement from '@amo/core/assets/images/icons/paiement.svg';
import opinions from '@amo/core/assets/images/logos/opinion.svg';
import _ from 'lodash';
import footer_logos from "@amo/core/assets/images/footer_recommande.png";
import gilet_inmotion from '../../../../assets/images/footer/gilet_inmotion.svg';
import logo_inmotion from '../../../../assets/images/footer/in&motion.png';
import logo_liberty from '../../../../assets/images/footer/logo_liberty.svg';
import logo_sentidrive from '../../../../assets/images/footer/logo-sentidrive.png';
import phone_liberty from '../../../../assets/images/footer/phone_liberty.svg';
import appli_mobile_sentidrive from '../../../../assets/images/footer/appli-mobile-sentidrive.png';
import { getStepBySlug } from '../../../../utils/function';

const Footer = ({ gestionnaireIsAMO, step, data }) => {
    const recontactByPostalCode = _.get(data, 'recontactByPostalCode') || false;

    return (
        <>
            <div className={`footer f-13 d-lg-block ${step === getStepBySlug('votre-tarif') && 'bg-grey'}`}>
                {!recontactByPostalCode && (
                    <>
                        {gestionnaireIsAMO && (
                            <div className={'container p-5'}>
                                <div className="row">
                                    <div className={'col-12 text-center'}>
                                        <img
                                            src={main}
                                            alt={'Et en plus, des offres exclusives rien que pour vous'}
                                            height={56}
                                        />
                                        <h2 className={'f-24 mb-4'}>
                                            Et en plus, des <b>offres exclusives</b> rien que <b>pour vous</b>
                                        </h2>
                                    </div>
                                    <div
                                        className="col-lg-4 col-12 d-flex align-items-center text-start mb-lg-0 mb-4 justify-content-lg-end">
                                        <img src={phone_liberty} alt={'Liberty Rider'}/>
                                        <p className={'ms-1 fw-semibold text-dark'}>
                                            <img
                                                src={logo_liberty}
                                                alt={'Liberty Rider Logo'}
                                                className={'d-block mb-lg-3 mb-2'}
                                            />
                                            Abonnement Premium d’1 an à<br/>
                                            l’application Liberty Rider inclus*
                                            <small className={'d-block f-10 fw-normal'}>
                                                *pour toute souscription à un contrat Moto ou Scooter
                                            </small>
                                        </p>
                                    </div>
                                    <div
                                        className="col-lg-4 col-12 d-flex align-items-center text-start mb-lg-0 mb-4 justify-content-lg-end">
                                        <img src={appli_mobile_sentidrive} alt={'Sentidrive'} width={100}/>
                                        <p className={'ms-1 fw-semibold text-dark'}>
                                            <img
                                                src={logo_sentidrive}
                                                alt={'Sentidrive Logo'}
                                                className={'d-block mb-lg-3 mb-2'}
                                                width={113}
                                            />
                                            15% de réduction sur l'achat de votre traceur GPS
                                            <small className={'d-block f-10 fw-normal'}>
                                            </small>
                                        </p>
                                    </div>
                                    <div
                                        className="col-lg-4 col-12 d-flex align-items-center text-start justify-content-lg-start">
                                        <img src={gilet_inmotion} alt={'In & Motion'}/>
                                        <p className={'ms-1 fw-semibold text-dark'}>
                                            <img
                                                src={logo_inmotion}
                                                alt={'In&Motion Logo'}
                                                className={'d-block mb-lg-3 mb-2'}
                                                width={100}
                                            />
                                            3 mois de location d’airbag
                                            <br/>
                                            In&Motion offerts<sup className={'f-10'}>**</sup>
                                            <small className={'d-block f-10 fw-normal'}>
                                                **pour les motards équipés d’un airbag.
                                                <br/>
                                                Pour toute souscription à un contrat Moto ou Scooter
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div
                            className={`container-fluid d-lg-block d-none ${
                                step === getStepBySlug('votre-tarif') ? 'bg-linear-2' : 'bg-linear'
                            }`}
                        >
                            <div className={'container'}>
                                <div className={'px-5'}>
                                    <div className={'row align-items-center'}>
                                        {gestionnaireIsAMO && (
                                            <>
                                                <div className={'col-2'}>
                                                    &nbsp;
                                                </div>
                                                <div className={'col-5'}>
                                                    <img src={footer_logos} alt={'Recommande APRIL Moto'}/>
                                                </div>
                                                <div className={'col-4 d-flex'}>
                                                    <div className={'left'}>
                                                        <img src={notes} alt={'+3000votes'} />
                                                        <p
                                                            className={'f-11 fw-semibold text-grey'}
                                                            style={{ marginLeft: 63 }}
                                                        >
                                                            sur 3&nbsp;728 votes{' '}
                                                        </p>
                                                    </div>
                                                    <div className={'right ms-4'}>
                                                        <a
                                                            href={
                                                                'https://www.opinion-assurances.fr/assureur-april-moto.html'
                                                            }
                                                            target={'_blank'}
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img
                                                                src={opinions}
                                                                alt={'Opinions Assurances'}
                                                                width={98}
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {!gestionnaireIsAMO && (
                                            <div className={'col-5'} style={{ height: 100 }}>
                                                &nbsp;
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className={`container-fluid bg-grey d-block d-lg-none d-xl-none py-4`}>
                    <div className={'row align-items-center'}>
                        {gestionnaireIsAMO && (
                            <>
                                <div className={'col-12 text-center mb-4'}>
                                    <img src={footer_logos} alt={'Label Excellence'} className={'img-fluid'} />
                                </div>
                                <div className={'col-12 d-flex align-items-center justify-content-center mb-5'}>
                                    <div className={'left'}>
                                        <img src={notes} alt={'+3000votes'} />
                                        <p className={'f-11 fw-semibold text-grey'} style={{ marginLeft: 63 }}>
                                            sur 3&nbsp;728 votes{' '}
                                        </p>
                                    </div>
                                    <div className={'right ms-4'}>
                                        <a
                                            href={'https://www.opinion-assurances.fr/assureur-april-moto.html'}
                                            target={'_blank'}
                                            rel="noopener noreferrer"
                                        >
                                            <img src={opinions} alt={'Opinions Assurances'} width={98} />
                                        </a>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className={'col-12 text-center mb-5'}>
                            <p className={'text-grey f-12 fw-bold'}>
                                <img src={locker} alt={'Paiement sécurisé'} />
                                Paiement sécurisé <img src={paiement} alt={'Paiement sécurisé'} />
                            </p>
                        </div>
                    </div>
                </div>
                <div className={`container-fluid p-4 f-12 bg-white`}>
                    <div className="row footer-link ">
                        <div className="col-12 text-center">
                            {gestionnaireIsAMO && (
                                <a
                                    href={
                                        'https://www.april-moto.com/wp-content/uploads/2022/07/26.06.2022-CGU-Groupe-April.pdf'
                                    }
                                    target={'_blank'}
                                    rel="noopener noreferrer"
                                    className={'text-grey mx-4 d-block d-lg-inline-block mb-2 mb-lg-0'}
                                >
                                    Mentions légales
                                </a>
                            )}
                            <a
                                href={
                                    gestionnaireIsAMO
                                        ? 'https://www.april-moto.com/gestion-des-cookies/'
                                        : 'https://amgestionassurance.com/gestion-cookies.html'
                                }
                                target={'_blank'}
                                rel="noopener noreferrer"
                                className={'text-grey mx-4 d-block d-lg-inline-block mb-2 mb-lg-0'}
                            >
                                Gestion des cookies
                            </a>
                            <a
                                href={
                                    gestionnaireIsAMO
                                        ? 'https://www.april-moto.com/wp-content/uploads/2021/11/Lettre-RGPD-APRIL-Moto-AM21-11.pdf'
                                        : 'https://www.amgestionassurance.com/Doc/Lettre-RGPD-AM-Gestion-11.2021.pdf'
                                }
                                target={'_blank'}
                                rel="noopener noreferrer"
                                className={'text-grey mx-4 d-block d-lg-inline-block mb-2 mb-lg-0'}
                            >
                                Politique des données personnelles
                            </a>
                            <a
                                href={
                                    gestionnaireIsAMO
                                        ? 'https://assets.april.fr/prismic/documents/juridique/doc-april-traitement-reclamations.pdf?func=proxy'
                                        : 'https://amgestionassurance.com/#reclamations'
                                }
                                target={'_blank'}
                                rel="noopener noreferrer"
                                className={'text-grey mx-4 d-block d-lg-inline-block mb-2 mb-lg-0'}
                            >
                                Traitement de vos réclamations
                            </a>
                            {gestionnaireIsAMO && (
                                <a
                                    href={
                                        'https://www.april-moto.com/wp-content/uploads/2022/07/26.06.2022-Rubrique-Fonctionnement_Groupe-April.pdf'
                                    }
                                    target={'_blank'}
                                    rel="noopener noreferrer"
                                    className={'text-grey mx-4 d-block d-lg-inline-block mb-2 mb-lg-0'}
                                >
                                    Fonctionnement du Tarificateur
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
