import info from '@amo/core/assets/images/icons/infos_conducteur.png';
import { Question } from '@amo/core/components/containers';
import { LargeSpinner } from '@amo/core/components/loaders';
import {
    Civilite,
    Immatriculation,
    Nom,
    NomNaissance,
    NumeroSerie,
    PaysNaissance,
    Prenom,
    VilleNaissance,
} from '@amo/core/components/questions';
import { InformationEntreprise } from '@amo/core/components/questions/InformationEntreprise/InformationEntreprise.jsx';
import { getAddress } from '@amo/core/utils/requestsApi';
import { validateImmatriculation, validatePhone } from '@amo/core/utils/validateField';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import Adresse from './Adresse/Adresse';
import Email from './Email/Email';
import {ImmatOuMarquage} from '@amo/core/components/questions';
import NumeroTel from './NumeroTel/NumeroTel';
import Permis from './Permis/Permis';
import MessageNomPrenom from "./MessageNomPrenom/MessageNomPrenom.jsx";

const InformationsSouscripteur = (props) => {
    const { data, postSaveDevis, saveDevisLoading } = props;
    const IndexSouscripteur = _.includes(
        ['true', '1', true],
        _.get(data, 'DemandeTarif[ListePersonnes][0][ConducteurMineur]'),
    )
        ? 1
        : 0;
    const [hasSearchAddress, setHasSearchAddress] = useState(false);
    const [tryPost, setTryPost] = useState(false);
    const [immatDisabled, setImmatDisabled] = useState(_.get(data, 'DemandeTarif[Vehicule][Immatriculation]') && _.get(data, 'searchBy') === 'immatriculation');

    useEffect(() => {
        if (tryPost) {
            isNotValid();
        }
    }, [data, tryPost]);

    /* Function qui ajoute la class error sur les champs vide */
    const handleError = (fieldName, remove) => {
        if (document.getElementsByName(fieldName).length > 0) {
            _.forEach(document.getElementsByName(fieldName), (input) => {
                if (input.getAttribute('type') === 'radio') {
                    if (remove) {
                        input.labels[0].classList.remove('error');
                    } else {
                        input.labels[0].classList.add('error');
                    }
                } else {
                    if (remove) {
                        input.classList.remove('error');
                    } else {
                        input.classList.add('error');
                    }
                }
            });
        } else {
            /* Gestion des autocomplete */
            if (remove) {
                document.querySelectorAll(`[data-name='${fieldName}']`)[0]?.classList.remove('error');
            } else {
                document.querySelectorAll(`[data-name='${fieldName}']`)[0]?.classList.add('error');
            }
        }
    };

    const isNotValid = () => {
        let notValid = false;
        let fields = [
            _.get(data, 'titulaireCarteGrise') !== '2'
                ? `DemandeTarif[ListePersonnes][${IndexSouscripteur}][Civilite]`
                : `DemandeTarif[ListePersonnes][1][Nom]`,
            _.get(data, 'titulaireCarteGrise') !== '2'
                ? `DemandeTarif[ListePersonnes][${IndexSouscripteur}][Nom]`
                : `DemandeTarif[ListePersonnes][1][LegalStatus]`,
            _.get(data, 'titulaireCarteGrise') !== '2'
                ? `DemandeTarif[ListePersonnes][${IndexSouscripteur}][BirthName]`
                : `DemandeTarif[ListePersonnes][1][IdSociety]`,
            _.get(data, 'titulaireCarteGrise') !== '2'
                ? `DemandeTarif[ListePersonnes][${IndexSouscripteur}][Prenom]`
                : '',
            _.get(data, 'titulaireCarteGrise') !== '2'
                ? `DemandeTarif[ListePersonnes][${IndexSouscripteur}][BirthCountry]`
                : '',
            _.get(data, 'titulaireCarteGrise') !== '2'
                ? `DemandeTarif[ListePersonnes][${IndexSouscripteur}][BirthCity]`
                : '',
            _.includes(['true', '1', true], _.get(data, 'DemandeTarif[ListePersonnes][0][ConducteurMineur]'))
                ? `DemandeTarif[ListePersonnes][0][Civilite]`
                : '',
            _.includes(['true', '1', true], _.get(data, 'DemandeTarif[ListePersonnes][0][ConducteurMineur]'))
                ? `DemandeTarif[ListePersonnes][0][Nom]`
                : '',
            _.includes(['true', '1', true], _.get(data, 'DemandeTarif[ListePersonnes][0][ConducteurMineur]'))
                ? `DemandeTarif[ListePersonnes][0][BirthName]`
                : '',
            _.includes(['true', '1', true], _.get(data, 'DemandeTarif[ListePersonnes][0][ConducteurMineur]'))
                ? `DemandeTarif[ListePersonnes][0][Prenom]`
                : '',
            `DemandeTarif[ListePersonnes][0][Adresse1]`,
            `DemandeTarif[ListePersonnes][0][CP]`,
            `DemandeTarif[ListePersonnes][0][Ville]`,
            `DemandeTarif[ListePersonnes][0][TelPortable]`,
            `DemandeTarif[ListePersonnes][0][Email]`,
            `EmailValidation`,
        ];

        fields.forEach((field) => {
            if (!_.get(data, field) && field !== '') {
                handleError(field);
                notValid = true;
            } else {
                handleError(field, true);
            }
        });

        if (_.get(data, 'immatriculation_check') !== '0') {
            if (validateImmatriculation(_.get(data, 'DemandeTarif[Vehicule][Immatriculation]'))) {
                notValid = true;
            }
        }

        if (validatePhone(_.get(data, 'DemandeTarif[ListePersonnes][0][TelPortable]'))) {
            notValid = true;
        }

        return notValid;
    };

    /* Envoie d'une fonction de validation  */

    const myPostSaveDevis = async () => {
        setTryPost(true);
        if (isNotValid()) {
            document.getElementsByClassName('error')[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
            if (hasSearchAddress) {
                getAddress(_.get(data, 'DemandeTarif[ListePersonnes][0][Adresse1]'), 'ValidateForm');
            }
            postSaveDevis();
        }
    };

    return (
        <>
            {saveDevisLoading ? (
                <LargeSpinner message={"Nous enregistrons votre proposition d'assurance"} />
            ) : (
                <div id={'etape-informations'} className={'fade-in'}>
                    <Question
                        loader
                        icon={info}
                        title={
                            _.includes(
                                ['true', '1', true],
                                _.get(data, 'DemandeTarif[ListePersonnes][0][ConducteurMineur]'),
                            )
                                ? 'Coordonnées du souscripteur'
                                : 'Vos coordonnées'
                        }
                    >
                        {_.get(data, 'titulaireCarteGrise') !== '2' ? (
                            <>
                                <Civilite {...props} index={IndexSouscripteur} />
                                <Nom {...props} index={IndexSouscripteur} />
                                <NomNaissance {...props} index={IndexSouscripteur} required />
                                <Prenom {...props} index={IndexSouscripteur} />
                                {
                                    (data?.DemandeTarif?.ListePersonnes[IndexSouscripteur]?.Prenom &&
                                        (
                                            _.toLower(data?.DemandeTarif?.ListePersonnes[IndexSouscripteur]?.Nom) === _.toLower(data?.DemandeTarif?.ListePersonnes[IndexSouscripteur]?.Prenom) ||
                                            _.toLower(data?.DemandeTarif?.ListePersonnes[IndexSouscripteur]?.BirthName) === _.toLower(data?.DemandeTarif?.ListePersonnes[IndexSouscripteur]?.Prenom)
                                        )
                                    ) && <MessageNomPrenom/>
                                }
                                <PaysNaissance {...props} index={IndexSouscripteur} required />
                                <VilleNaissance {...props} index={IndexSouscripteur} required />
                            </>
                        ) : (
                            <>
                                <InformationEntreprise needAdresse={true} {...props} />
                            </>
                        )}

                        <Adresse {...props} setHasSearchAddress={setHasSearchAddress} />

                        <NumeroTel {...props} />

                        <Email {...props} />
                    </Question>

                    {(_.includes(
                        ['true', '1', true],
                        _.get(data, 'DemandeTarif[ListePersonnes][0][ConducteurMineur]'),
                    ) ||
                        _.get(data, 'titulaireCarteGrise') === '2') && (
                        <Question icon={info} title={'Coordonnées du conducteur'} loader>
                            <Civilite {...props} index={0} />
                            <Nom {...props} index={0} />
                            <NomNaissance {...props} index={0} required />
                            <Prenom {...props} index={0} />
                            {
                                (data?.DemandeTarif?.ListePersonnes[0]?.Prenom &&
                                    (
                                        _.toLower(data?.DemandeTarif?.ListePersonnes[0]?.Nom) === _.toLower(data?.DemandeTarif?.ListePersonnes[0]?.Prenom) ||
                                        _.toLower(data?.DemandeTarif?.ListePersonnes[0]?.BirthName) === _.toLower(data?.DemandeTarif?.ListePersonnes[0]?.Prenom)
                                    )
                                ) && <MessageNomPrenom/>
                            }
                            <PaysNaissance {...props} index={0} required />
                            <VilleNaissance {...props} index={0} required />
                        </Question>
                    )}

                    <Question title={'Informations complémentaires'} loader>
                        <Permis {...props} />

                        {_.get(data, 'immatriculation_check') === '0' ? (
                            <NumeroSerie {...props} />
                        ) : (
                            <Immatriculation {...props} disabled={immatDisabled} />
                        )}
                        <ImmatOuMarquage {...props} />
                    </Question>
                    <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => myPostSaveDevis()}>
                        {_.get(data, 'typeDevis') === 'subscribe' ? 'Continuer' : 'Recevoir ma proposition'}
                    </button>
                </div>
            )}
        </>
    );
};

export default InformationsSouscripteur;
