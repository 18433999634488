import Cylindre from '@amo/core/assets/images/AMO_Icons/Cylindre.svg';
import InformationsBleu from '@amo/core/assets/images/icons/informations-bleu.png';
import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import { LargeSpinner } from '@amo/core/components/loaders';
import { showModal } from '@amo/core/utils/functions';
import _ from 'lodash';
import { useEffect } from 'react';
import { Field } from 'redux-form';
import carte_grise from '@amo/core/assets/images/bulle/cylindree.jpg';

const CylindreeVehicule = (props) => {
    const { nextQuestion, type, fetchCylindreeVehicule, marque_id, cylindrees, cylindree, loading, changeValue, previousQuestion } = props;

    useEffect(() => {
        marque_id && fetchCylindreeVehicule({ type, marque_id });
    }, [marque_id, fetchCylindreeVehicule, type]);

    return (
        <Question icon={Cylindre} title={'Quelle est la <b>cylindrée</b> <br/>de votre <b>véhicule</b>&nbsp;?'}>
            <div className={'row justify-content-center mt-2'}>
                <p
                    className={'f-12 text-secondary mb-3'}
                    onClick={() =>
                        showModal({
                            imageUrl: carte_grise,
                            html: `<p>La cylindrée de votre véhicule est renseignée en case <strong>P.1</strong> de la carte grise.</p>`,
                        })
                    }
                >
                    <img src={InformationsBleu} alt="?" className={'align-middle'}/>{' '}
                    <span className={'pb-1 cursor-pointer underline-link'}>Où trouver la cylindrée du véhicule ?</span>
                </p>

                {loading && <LargeSpinner message={'Nous récupérons nos cylindrées'}/>}

                {cylindrees &&
                    cylindrees.map((cylindree, index) => (
                        <div key={index} className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                            <Field
                                component={Radio}
                                type={'radio'}
                                name={'cylindreeVehicule'}
                                value={String(cylindree)}
                                label={cylindree}
                                className={'small'}
                                onClick={() => nextQuestion(300)}
                            />
                        </div>
                    ))}
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${!cylindree && 'disabled'}`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
            <span
                type="button"
                className={`mt-4 d-block`}
                style={{cursor: 'pointer', textDecoration: 'underline'}}
                onClick={() => {
                    previousQuestion();
                    changeValue('searchBy', 'immatriculation');
                }}
            >
                    Je préfère rechercher par immatriculation.
                </span>
        </Question>
    );
};

export default CylindreeVehicule;
