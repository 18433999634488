import opinions from '@amo/core/assets/images/icons/opinions_assurances.svg';
import lesfurets from '@amo/core/assets/images/logos/landing/100122.png';
import lelynx from '@amo/core/assets/images/logos/landing/101853.png';
import meilleureassurance from '@amo/core/assets/images/logos/landing/393798.png';
import assurland from '@amo/core/assets/images/logos/landing/67256.png';
import { getIconByType } from '@amo/core/utils/functions';
import CodePromoContainer from '../CodePromo/CodePromoContainer';
import Franchises from '../Franchises/Franchises';
import Options from '../Options/Options';
import SouscrireContainer from '../Souscrire/SouscrireContainer';
import '../tarif.scss';
import getFormuleChoisie from '../utilsTarif/getFormuleChoisie';
import TableFormule from './TableFormule/TableFormule';
import FranchisesInfo from "../Franchises/FranchisesInfo";
import {Avis} from "@amo/core/components/containers/Avis/Avis.jsx";
import avis1 from '@amo/core/assets/images/icons/clients/avis-1.png';
import avis2 from '@amo/core/assets/images/icons/clients/avis-2.png';
import avis3 from '@amo/core/assets/images/icons/clients/avis-3.png';

const TableauGarantiesDesktop = (props) => {
    const { data, goTo, vehicule, formules, franchises, nameWhitelabel, apporteur2, IDDevis, apporteur1, analyticsSetEvent, infoDTA, infoVI} = props;
    const formuleChoisie = getFormuleChoisie(data, formules);
    const logos = { 67256: assurland, 100122: lesfurets, 101853: lelynx, 393798: meilleureassurance };

    return (
        <>
            <div className={'d-none d-lg-block'}>
                <div className={'row bg-grey'}>
                    <div
                        className={`bg-blue rounded-bottom-medium p-5 ${
                            apporteur1.codeBelair === 43397 && 'header-tarif'
                        } ${apporteur2 && 'header-landing'} position-relative`}
                    >
                        {apporteur2 ? (
                            <>
                                <p className={'text-white f-32 fw-bold mx-5 mt-5 mb-3 pt-lg-5'}>
                                    Vous êtes au meilleur endroit <br />
                                    pour assurer votre <span className={'text-dark'}>
                                        {vehicule.brand?.name}
                                    </span>...{' '}
                                </p>
                                <p className={'text-white'}>
                                    Bonjour, bienvenue chez <span className={'fw-bold'}>{nameWhitelabel}.</span>
                                </p>
                                <p className={'text-white mb-3'}>
                                    Comme vous, <span className={'fw-bold'}>500 000 motards</span> nous ont déjà fait
                                    confiance.
                                </p>
                                <p className={'text-white'}>
                                    Dans 3 clics, vous aurez{' '}
                                    <span className={'fw-bold text-dark'}>votre attestation d'assurance&nbsp;!</span>
                                </p>

                                <div
                                    className={'px-4 py-3 bg-white mt-3 rounded position-absolute bloc-landing shadow'}
                                >
                                    {IDDevis && (
                                        <p className={'f-14 text-dark text-start fw-bold'}>
                                            Votre devis : <span className={'m-0 fw-bold'}>N°{IDDevis}</span>
                                        </p>
                                    )}
                                    <img src={logos[apporteur2.codeBelair]} alt="Logo" className={'pt-2'} width={100} />
                                </div>
                            </>
                        ) : (
                            <p className={'text-white f-32 fw-bold p-5 pt-4 text-start'}>
                                <span className={'d-block mb-4'}>
                                    Choisissez votre formule <br />
                                    pour assurer votre véhicule
                                </span>
                                {apporteur1.codeBelair === 43397 && (
                                    <img src={opinions} alt={'Opinions Assurances'} />
                                )}
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <div className={'bg-grey d-none d-lg-block'}>
                <div className={'container'}>
                    <div className="row justify-content-between position-relative">
                        <div className="pt-md-5 pt-xxl-0 mt-md-5 mt-xxl-0 col-12 d-flex">
                            <div className={'px-4 py-3 bg-white mt-3 rounded position-absolute bloc-vehicule shadow'}>
                                <p className={'f-14 text-dark text-start fw-bold'}>
                                    Votre deux-roues{' '}
                                    <span
                                        className={'text-secondary f-12 float-end cursor-pointer underline-link'}
                                        onClick={() => {
                                            analyticsSetEvent({event: 'modifMoto'})
                                            goTo({ step: 0, question: 1 })}
                                        }
                                    >
                                        modifier
                                    </span>
                                </p>
                                <div className={'row mt-2'}>
                                    <div className="col-3">
                                        <img
                                            src={getIconByType(data?.typeVehicule)}
                                            alt="Véhicule"
                                            className={'pt-2'}
                                        />
                                    </div>

                                    <div className="col-7 text-start">
                                        <p className={'m-0 fw-bold'}>
                                            {vehicule.brand?.name} {vehicule.cylindree}
                                        </p>
                                        <small>
                                            {vehicule.modele}{' '}
                                            {`${
                                                vehicule.annee_debut_version !== null &&
                                                vehicule.annee_debut_version !== 0
                                                    ? `depuis ${vehicule.annee_debut_version}`
                                                    : ''
                                            } ${
                                                vehicule.annee_fin_version !== null && vehicule.annee_fin_version !== 0
                                                    ? ` jusqu'à ${vehicule.annee_fin_version}`
                                                    : ''
                                            }`}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <TableFormule formuleChoisie={formuleChoisie} {...props} />
                        </div>
                        <div className={'col-12'}>
                            <Options {...props} />

                            {franchises.labelGroupe
                                ? <Franchises {...props} />
                                : (infoVI[0] && infoDTA[0] && (infoVI[0].Disponible !== 'NonDisponible' || infoDTA[0].Disponible !== 'NonDisponible')) &&
                                <FranchisesInfo {...props}
                                                VI={(infoVI[0] && infoVI[0].Disponible !== 'NonDisponible') ? infoVI[0] : null}
                                                DTA={(infoDTA[0] && infoDTA[0].Disponible !== 'NonDisponible') ? infoDTA[0] : null}
                                />
                            }

                            <CodePromoContainer {...props} />

                            <SouscrireContainer formuleChoisie={formuleChoisie} {...props} />

                            <div className={'container mt-5'}>
                                <div className={'row'}>
                                    <Avis
                                        name={'Maëla R'}
                                        icon={avis1}
                                        content={'Meilleure assurance qualité prix au niveau moto en tout risques, déjà assuré au niveau mutuelle je suis\n' +
                                            'très satisfaite d\'April en tout point je recommande'}
                                        date={'24/01/2024'}
                                    />
                                    <Avis
                                        name={'Corentin E'}
                                        icon={avis2}
                                        content={'Je suis très satisfait du service proposé par April moto. Les Prix sont très concurrentiels, pour les mêmes\n' +
                                            'garanties je gagne 50€ par mois. Je recommande'}
                                        date={'23/01/2024'}
                                    />
                                    <Avis
                                        name={'Michel D'}
                                        icon={avis3}
                                        content={'Deuxième inscription. Je suis satisfait des prix et de la rapidité pour s\'inscrire. Je conseillerai cette\n' +
                                            'assurance à d\'autres motards. Je suis moi-même motard sénior'}
                                        date={'26/01/2024'}
                                        isLast={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TableauGarantiesDesktop;
